import { PATHS } from 'constants/paths';

export const LINKS = {
  OFFER: {
    name: 'Oferta',
    link: PATHS.OFFER,
  },
  LEXICON: {
    name: 'Leksykon',
    link: PATHS.LEXICON,
  },
  TREATMENTS_BOTOX: {
    name: 'Toksyna Botulinowa',
    link: `${PATHS.OFFER}${PATHS.OFFER_BOTOX}`,
  },
  TREATMENTS_HIALURONIC_ACID: {
    name: 'Kwas Hialuronowy',
    link: `${PATHS.OFFER}${PATHS.OFFER_HYALURONIC_ACID}`,
  },
  TREATMENTS_TISSUE_STIMULATOR: {
    name: 'Stymulatory tkankowe',
    link: `${PATHS.OFFER}${PATHS.OFFER_TISSUE_STIMULATORS}`,
  },
  TREATMENTS_MESOTHERAPY: {
    name: 'Mezoterapia',
    link: `${PATHS.OFFER}${PATHS.OFFER_MESOTHERAPY}`,
  },
};
