import idealeFb from '../images/ideale_fb.png';

export const TAGS = {
  'badanie-histopatologiczne': {
    'og:url': 'https://ideale.pl/leksykon/badanie-histopatologiczne',
    'og:type': 'article',
    'og:title': 'Badanie histopatologiczne - IDEALE',
    'og:description':
      'Opis pojęcia badanie, badania histopatologiczne. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  anagen: {
    'og:url': 'https://ideale.pl/leksykon/anagen',
    'og:type': 'article',
    'og:title': 'Anagen - aktywna faza wzrostu włosa.',
    'og:description':
      'Opis pojęcia anagen. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  angiologia: {
    'og:url': 'https://ideale.pl/leksykon/angiologia',
    'og:type': 'article',
    'og:title': 'Angiologia - leczenie układu limfatycznego',
    'og:description':
      'Opis pojęcia angiologia. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'anty-aging': {
    'og:url': 'https://ideale.pl/leksykon/anty-aging',
    'og:type': 'article',
    'og:title': 'Anty aging - odmłodzenie i hamowanie starzenia.',
    'og:description':
      'Opis pojęcia anty-aging. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'asymetria-ust': {
    'og:url': 'https://ideale.pl/leksykon/asymetria-ust',
    'og:type': 'article',
    'og:title': 'Asymetria ust- IDEALE',
    'og:description':
      'Opis pojęcia asymetrii ust. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  bruksizm: {
    'og:url': 'https://ideale.pl/leksykon/bruksizm',
    'og:type': 'article',
    'og:title': 'Bruksizm - zaciskanie i zgrzytanie zębami',
    'og:description':
      'Opis pojęcia bruksizmu. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'gummy-smile': {
    'og:url': 'https://ideale.pl/leksykon/gummy-smile',
    'og:type': 'article',
    'og:title': 'Gummy-smile - IDEALE',
    'og:description':
      'Opis pojęcia gummy smile. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'jak-poprawic-owal-twarzy': {
    'og:url': 'https://ideale.pl/leksykon/jak-poprawic-owal-twarzy',
    'og:type': 'article',
    'og:title': 'Jak poprawić owal twarzy? Sprawdź zabiegi w IDEALE!',
    'og:description':
      'Najskuteczniejsze zabiegi na poprawę owalu twarzy. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'jak-pozbyc-sie-zmarszczek': {
    'og:url': 'https://ideale.pl/leksykon/jak-pozbyc-sie-zmarszczek',
    'og:type': 'article',
    'og:title': 'Jak pozbyć się zmarszczek? Sprawdź zabiegi w IDEALE!',
    'og:description':
      'Jak pozbyć się zmarszczek? Sprawdź zabiegi w IDEALE! Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'jak-pozbyc-sie-zmarszczek-na-czole': {
    'og:url': 'https://ideale.pl/leksykon/jak-pozbyc-sie-zmarszczek-na-czole',
    'og:type': 'article',
    'og:title': 'Jak pozbyć się zmarszczek na czole? Sprawdź zabiegi w IDEALE!',
    'og:description':
      'Jak pozbyć się zmarszczek na czole? Sprawdź zabiegi w IDEALE! Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'pelne-usta': {
    'og:url': 'https://ideale.pl/leksykon/pelne-usta',
    'og:type': 'article',
    'og:title': 'Piękne i pełne usta z medycyną estetyczną.',
    'og:description':
      'Jak uzyskać efekt pełnych ust? Sprawdź zabiegi w IDEALE! Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  rodo: {
    'og:url': 'https://ideale.pl/leksykon/rodo/',
    'og:type': 'article',
    'og:title': 'Polityka Prywatności | IDEALE',
    'og:description':
      'Polityka prywatności IDEALE Medycyna Estetyczna. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'regulamin-akcji-voucher': {
    'og:url': 'https://ideale.pl/leksykon/regulamin-akcji-voucher/',
    'og:type': 'article',
    'og:title': 'Voucher 100 PLN | IDEALE',
    'og:description':
      'Regulamin akcji promocyjne voucher 100 PLN za newsletter. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'polityka-plikow-cookies': {
    'og:url': 'https://ideale.pl/leksykon/polityka-plikow-cookies/',
    'og:type': 'article',
    'og:title': 'Polityka Plików Cookies | IDEALE',
    'og:description':
      'Polityka plików cookies IDEALE Medycyna Estetyczna. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  'zewnetrzne-zrodla': {
    'og:url': 'https://ideale.pl/leksykon/zewnetrzne-zrodla',
    'og:type': 'article',
    'og:title': 'Zewnętrzne źródła - IDEALE',
    'og:description':
      'Lista stron na której możecie Nas znaleźć. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
};
