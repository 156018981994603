import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import MEDIA from 'styles/media';

const SContainer = styled.article`
  margin-top: 212px;
  padding-bottom: 3.75rem;

  ${MEDIA.max1024`
    margin-top: 100px;
  `};
`;

export const PageWrapper = ({ children }) => {
  return <SContainer>{children}</SContainer>;
};

PageWrapper.propTypes = {
  children: node.isRequired,
};
