import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const STextWrapper = styled.div`
  width: 100%;

  h1 {
    margin: 2rem 0 0.75rem;
    font-family: 'Abhaya Libre', serif;
    font-weight: bold;
    color: ${COLORS.LIGHT_BLACK};
    font-size: 2.25rem;
  }

  h2 {
    margin: 1.75rem 0 0.75rem;
    font-family: 'Abhaya Libre', serif;
    font-weight: bold;
    color: ${COLORS.DARK_BLACK};
    font-size: 2rem;
  }

  h3 {
    margin: 1rem 0 0.75rem;
    font-family: 'Abhaya Libre', serif;
    color: ${COLORS.MEDIUM_BLACK};
    font-size: 1.5rem;
  }

  h4,
  h5,
  h6 {
    margin: 1rem 0 0.75rem;
    font-family: 'Abhaya Libre', serif;
    color: ${COLORS.LIGHT_BLACK};
    font-size: 1.75rem;
  }

  p {
    margin-bottom: 1rem;
    color: ${COLORS.GREY};
    line-height: 1.5;
    font-size: 1.25rem;
  }
`;

export const TextWrapper = ({ children }) => {
  return <STextWrapper>{children}</STextWrapper>;
};

TextWrapper.propTypes = {
  children: node.isRequired,
};
