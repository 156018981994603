import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const SSectionTitle = styled.h2`
  font-size: 3rem;
  font-family: 'Abhaya Libre', serif;
  text-align: center;
  margin-top: 1rem;
`;

export const SectionTitle = (props) => {
  const { title, titleSecond, className } = props;
  return (
    <SSectionTitle className={className}>
      <span>{title}</span>
      <span>{titleSecond}</span>
    </SSectionTitle>
  );
};

SectionTitle.defaultProps = {
  className: '',
  titleSecond: '',
};

SectionTitle.propTypes = {
  title: string.isRequired,
  titleSecond: string,
  className: string,
};
