import React from 'react';
import { TermView } from 'views/term';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags-lexicon';
import { graphql } from 'gatsby';
import { Layout } from 'layouts';
import { object } from 'prop-types';

const TermPage = ({ data }) => {
  const { markdownRemark } = data;
  const {
    frontmatter: { title, pageId },
    html,
  } = markdownRemark;

  return (
    <Layout>
      <TermView title={title} html={html} pageId={pageId} />
    </Layout>
  );
};
export default TermPage;

export const Head = ({ data }) => {
  const { markdownRemark } = data;
  const {
    frontmatter: { pageId },
  } = markdownRemark;

  return <SEO tags={TAGS[pageId]} />;
};

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        pageId
      }
    }
  }
`;

TermPage.propTypes = {
  data: object.isRequired,
};
